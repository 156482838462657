import { Box, Text } from "@chakra-ui/react";
import { ArrowLink } from "src/components/base/arrow-link";
import { ModulePrimaryHeadline } from "src/components/common/module-headlines";
import { ScrollSnap, ScrollSnapItem } from "src/components/common/scroll-snap";
import { Container, CONTAINER_PX_XS_SM, CONTAINER_PX_XS_SM_NEGATIVE } from "src/components/layout/container";
import { IBestsellerData } from "src/components/modules/bestseller-module/bestseller.types";
import { ILink } from "src/types/common";
import { Bestseller } from "./bestseller";

const THREE_BESTSELLER_MODULE_TEST_ID = "three-bestseller-module";

export interface IThreeBestsellerModuleProps {
    title: string;
    description: string;
    wikifolios: IBestsellerData[];
    more?: ILink;
}

export const ThreeBestsellerModule = ({ title, description, wikifolios, more }: IThreeBestsellerModuleProps) => (
    <Container data-test-id={THREE_BESTSELLER_MODULE_TEST_ID}>
        <ModulePrimaryHeadline mb={[3]}>{title}</ModulePrimaryHeadline>
        <Text fontSize="lg" mb={[4, 4, 4, 5]}>
            {description}
        </Text>
        <ScrollSnap
            p={[...CONTAINER_PX_XS_SM, 0]}
            m={[...CONTAINER_PX_XS_SM_NEGATIVE, 0]}
            gap={[2, 3]}
            overflowX={["auto", "auto", "visible"]}
            templateColumns={["repeat(3, auto)", "repeat(3, auto)", "repeat(3, minmax(0, 1fr))"]}
        >
            {wikifolios.map((wf, i) => (
                <ScrollSnapItem key={wf.id} pr={i === 2 ? [2, 2, 0] : undefined}>
                    <Bestseller data={wf} w={["288px", "358px", "auto"]} />
                </ScrollSnapItem>
            ))}
        </ScrollSnap>
        {more && (
            <Box textAlign={["left", "right"]} mt={5}>
                <ArrowLink href={more.url} target={more.target} colorScheme="green">
                    {more.name}
                </ArrowLink>
            </Box>
        )}
    </Container>
);
