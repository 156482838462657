import { GetServerSideProps } from "next";
import { EnumContentModuleType, fetchAreaOverview, IAreaOverviewProps } from "src/api/server/area-overview";
import { ErrorBoundary } from "src/components/error-handling";
import { AreaOverviewModule } from "src/components/modules/base/area-overview-module";
import { AREA_BOTTOM_MARGIN, AREA_TOP_MARGIN } from "src/consts/styles";
import { useContentPageVisitTracking } from "src/hooks/use-content-page-visit-tracking";
import { useReturnUrl } from "src/hooks/use-return-url";
import { serverSideTranslations } from "src/i18n/server-side-translations";
import { IBaseParam } from "src/types/common";
import { addServerTiming } from "src/utils/nextjs/add-server-timing";
import { numberToPx } from "src/utils/number-format-utils";

/** The general spacing step between content-modules */
const MODULE_SPACING_STEP = 80;
/** The spacing step of single spacing-modules */
const SPACING_MODULE_STEP = 40;

const getSpacing = (spacingStep: number, factorPx: number) => {
    const spacingDesktop = spacingStep * factorPx;
    const spacingSmall = spacingDesktop / 2;

    return [numberToPx(spacingSmall), numberToPx(spacingSmall), numberToPx(spacingDesktop)];
};

function Page({ data }: IAreaOverviewProps) {
    const { spacingStep: _spacingStep, modules } = data;
    useReturnUrl(data.returnUrl);
    useContentPageVisitTracking({});

    const spacing = getSpacing(_spacingStep, MODULE_SPACING_STEP);
    const getMarginBottom = (currentIndex: number) => {
        if (currentIndex === modules.length - 1) {
            // Last content module
            return AREA_BOTTOM_MARGIN;
        }

        const nextModule = modules[currentIndex + 1];
        if (nextModule.type === EnumContentModuleType.SPACING) {
            // Apply custom spacing if next is spacing module
            return getSpacing(nextModule.spacingStep, SPACING_MODULE_STEP);
        }

        return spacing;
    };

    return (
        <>
            {modules
                .map((module, index) => {
                    const mt = index === 0 ? AREA_TOP_MARGIN : undefined;
                    const mb = getMarginBottom(index);

                    return (
                        <ErrorBoundary key={module.id}>
                            <AreaOverviewModule moduleProps={{ ...module, isFirstModule: index === 0 }} sectionProps={{ mt, mb }} />
                        </ErrorBoundary>
                    );
                })
                .filter(Boolean)}
        </>
    );
}

type IParam = IBaseParam & {
    slug: string[];
};

export const getServerSideProps: GetServerSideProps<IAreaOverviewProps, IParam> = async context => {
    const { slug } = context.params!;

    const [props, translations] = await addServerTiming(context.res, "ssprops", async () => {
        const props = await fetchAreaOverview(slug.join("/"), context);
        const translations = await serverSideTranslations(context.locale as string, ["common", "voting-module", "underlying-overview", "date-range"]);

        return [props, translations];
    });

    const redirectUrl = props?.data?.redirectUrl;
    if (redirectUrl) {
        return {
            redirect: {
                destination: redirectUrl,
                permanent: false,
            },
        };
    }

    if (!props) {
        return { notFound: true };
    }
    return { props: { ...props, ...translations } };
};

export default Page;
