import { GetServerSidePropsContext } from "next";
import { IAutoContentModuleProps } from "src/components/modules/auto-content-module/auto-content-module";
import { ISingleBestsellerProps } from "src/components/modules/bestseller-module/single-bestseller-module";
import { IThreeBestsellerModuleProps } from "src/components/modules/bestseller-module/three-bestseller-module";
import { IBlogTeaserModuleProps } from "src/components/modules/blog-teaser-module/blog-teaser-module";
import { IBubbleChartModuleProps } from "src/components/modules/bubble-chart-module/bubble-chart-module";
import { ICookiebotDeclarationModule } from "src/components/modules/cookiebot-declaration-module/cookiebot-declaration-module";
import { ICtaModuleProps } from "src/components/modules/cta-module/cta-module.types";
import { IDisclaimerModuleProps } from "src/components/modules/disclaimer-module/disclaimer-module";
import { IFaqModuleProps } from "src/components/modules/faq-module/faq-module";
import { IFeesModuleProps } from "src/components/modules/fees-module/fees-module";
import { IHeaderForHomeModuleProps } from "src/components/modules/header-for-home-module/header-for-home-module";
import { IHeaderModuleProps } from "src/components/modules/header-module/header-module";
import { IImageVideoModuleProps } from "src/components/modules/image-video-module/image-video-module.types";
import { IJobsWidgetModuleProps } from "src/components/modules/jobs-widget-module/jobs-widget-module";
import { IListModuleProps } from "src/components/modules/list-module/list-module";
import { IMarketoModuleProps } from "src/components/modules/marketo-module/marketo-module";
import { IMockupTextModuleProps } from "src/components/modules/mockup-text-module/mockup-text-module";
import { IPartnerListModuleProps } from "src/components/modules/partner-list-module/partner-list-module";
import { IPartnerLogosModuleProps } from "src/components/modules/partner-logos-module/partner-logos-module";
import { IPreviewAdvantagesModuleProps } from "src/components/modules/preview-advantages-module/preview-advantages-module";
import { IMultiQuoteModuleProps } from "src/components/modules/quotes-module/multi-quote-module";
import { IRegisterModuleProps } from "src/components/modules/register-module/register-module";
import { ISearchModuleProps } from "src/components/modules/search-module/search-module";
import { ISelectedWikifoliosModuleProps } from "src/components/modules/selected-wikifolios/selected-wikifolios-module";
import { IStepsModuleProps } from "src/components/modules/steps-module/steps-module";
import { ITextImageModuleProps } from "src/components/modules/text-image-module/text-image-module";
import { IUnderlyingListModuleProps } from "src/components/modules/underlying-lists-module/underlying-list-module";
import { IVotingModuleProps } from "src/components/modules/voting-module/voting-module";
import { IWallOfTextModuleProps } from "src/components/modules/wall-of-text-module/wall-of-text-module";
import { createAreaOverviewPageUrl } from "src/consts/urls";
import { IBaseParam } from "src/types/common";
import { IBasePageProps } from "src/types/layout";
import { logger } from "src/utils/logger-util";
import { fetchUmbraco } from "src/utils/nextjs/api-util";

const FETCHING_AREA_OVERVIEW_ERROR_MESSAGE = "Error while fetching Area Overview data.";

interface IAreaOverviewDict {}

export enum EnumContentModuleType {
    SPACING = "Spacing",
    BLOG_TEASER = "BlogTeaser",
    PREVIEW_ADVANTAGES = "PreviewAdvantages",
    CTA = "Cta",
    FAQ = "Faq",
    FEES = "Fees",
    HEADER = "Header",
    IMAGE_VIDEO = "ImageVideo",
    PARTNER_LIST = "PartnerList",
    PARTNER_LOGOS = "PartnerLogos",
    REGISTER = "Register",
    STEPS = "Steps",
    TEXT_IMAGE = "TextImage",
    WALL_OF_TEXT = "WallOfText",
    LIST = "List",
    MOCKUP_TEXT = "MockupText",
    SELECTED_WIKIFOLIO = "SelectedWikifolios",
    QUOTE = "Quote",
    HEADER_FOR_HOME = "HeaderForHome",
    DISCLAIMER = "Disclaimer",
    THREE_BESTSELLER = "ThreeBestseller",
    SINGLE_BESTSELLER = "SingleBestseller",
    JOBS_WIDGET = "JobsWidget",
    MARKETO = "Marketo",
    AUTO_CONTENT = "AutoContent",
    SEARCH = "Search",
    COOKIEBOT_DECLARATION = "CookiebotDeclaration",
    VOTING = "MultiVoteModule",
    BUBBLE_CHART = "BubbleChart",
    UNDERLYING_LISTS = "UnderlyingLists",
}

interface IBaseAreaOverview {
    id: string;
    isFirstModule: boolean;
    hash?: string | null;
}

interface ISpacingModuleProps {
    /** Spacing in 40px steps - in Tab and Mobile auto. in 20px step */
    spacingStep: number;
}
interface ISpacing extends IBaseAreaOverview, ISpacingModuleProps {
    type: EnumContentModuleType.SPACING;
}

interface IBlogTeaser extends IBaseAreaOverview, IBlogTeaserModuleProps {
    type: EnumContentModuleType.BLOG_TEASER;
}

interface IPreviewAdvantages extends IBaseAreaOverview, IPreviewAdvantagesModuleProps {
    type: EnumContentModuleType.PREVIEW_ADVANTAGES;
}

interface ICta extends IBaseAreaOverview, ICtaModuleProps {
    type: EnumContentModuleType.CTA;
}

interface IFaq extends IBaseAreaOverview, IFaqModuleProps {
    type: EnumContentModuleType.FAQ;
}

interface IFees extends IBaseAreaOverview, IFeesModuleProps {
    type: EnumContentModuleType.FEES;
}

interface IHeader extends IBaseAreaOverview, IHeaderModuleProps {
    type: EnumContentModuleType.HEADER;
}

interface IImageVideo extends IBaseAreaOverview, IImageVideoModuleProps {
    type: EnumContentModuleType.IMAGE_VIDEO;
}

interface IPartnerList extends IBaseAreaOverview, IPartnerListModuleProps {
    type: EnumContentModuleType.PARTNER_LIST;
}

interface IPartnerLogos extends IBaseAreaOverview, IPartnerLogosModuleProps {
    type: EnumContentModuleType.PARTNER_LOGOS;
}

interface IRegister extends IBaseAreaOverview, IRegisterModuleProps {
    type: EnumContentModuleType.REGISTER;
}

interface ISteps extends IBaseAreaOverview, IStepsModuleProps {
    type: EnumContentModuleType.STEPS;
}

interface ITextImage extends IBaseAreaOverview, ITextImageModuleProps {
    type: EnumContentModuleType.TEXT_IMAGE;
}

interface IWallOfText extends IBaseAreaOverview, IWallOfTextModuleProps {
    type: EnumContentModuleType.WALL_OF_TEXT;
}

interface IList extends IBaseAreaOverview, IListModuleProps {
    type: EnumContentModuleType.LIST;
}

interface IMockupText extends IBaseAreaOverview, IMockupTextModuleProps {
    type: EnumContentModuleType.MOCKUP_TEXT;
}

interface ISelectedWikifolios extends IBaseAreaOverview, ISelectedWikifoliosModuleProps {
    type: EnumContentModuleType.SELECTED_WIKIFOLIO;
}

interface IQuote extends IBaseAreaOverview, IMultiQuoteModuleProps {
    type: EnumContentModuleType.QUOTE;
}

interface IHeaderForHome extends IBaseAreaOverview, IHeaderForHomeModuleProps {
    type: EnumContentModuleType.HEADER_FOR_HOME;
}

interface IDisclaimer extends IBaseAreaOverview, IDisclaimerModuleProps {
    type: EnumContentModuleType.DISCLAIMER;
}

interface IThreeBestseller extends IBaseAreaOverview, IThreeBestsellerModuleProps {
    type: EnumContentModuleType.THREE_BESTSELLER;
}

interface ISingleBestseller extends IBaseAreaOverview, ISingleBestsellerProps {
    type: EnumContentModuleType.SINGLE_BESTSELLER;
}

interface IJobsWidget extends IBaseAreaOverview, IJobsWidgetModuleProps {
    type: EnumContentModuleType.JOBS_WIDGET;
}

interface IMarketo extends IBaseAreaOverview, IMarketoModuleProps {
    type: EnumContentModuleType.MARKETO;
}

interface IAutoContent extends IBaseAreaOverview, IAutoContentModuleProps {
    type: EnumContentModuleType.AUTO_CONTENT;
}

interface ISearch extends IBaseAreaOverview, ISearchModuleProps {
    type: EnumContentModuleType.SEARCH;
}

interface ICookiebotDeclaration extends IBaseAreaOverview, ICookiebotDeclarationModule {
    type: EnumContentModuleType.COOKIEBOT_DECLARATION;
}

interface IVoting extends IBaseAreaOverview, IVotingModuleProps {
    type: EnumContentModuleType.VOTING;
}
interface IBubbleChart extends IBaseAreaOverview, IBubbleChartModuleProps {
    type: EnumContentModuleType.BUBBLE_CHART;
}

interface IUnderlyingLists extends IBaseAreaOverview, IUnderlyingListModuleProps {
    type: EnumContentModuleType.UNDERLYING_LISTS;
}

export type IAreaOverviewModule =
    | ISpacing
    | IBlogTeaser
    | IPreviewAdvantages
    | ICta
    | IFaq
    | IFees
    | IHeader
    | IImageVideo
    | IPartnerList
    | IPartnerLogos
    | IRegister
    | ISteps
    | ITextImage
    | IWallOfText
    | IList
    | IMockupText
    | ISelectedWikifolios
    | IQuote
    | IHeaderForHome
    | IDisclaimer
    | IThreeBestseller
    | ISingleBestseller
    | IJobsWidget
    | IMarketo
    | IAutoContent
    | ISearch
    | ICookiebotDeclaration
    | IVoting
    | IBubbleChart
    | IUnderlyingLists;

type TApiAreaOverviewModule = Exclude<IAreaOverviewModule, "isFirstModule">;

interface IAreaOverviewData {
    /**
     * Spacing in 80px steps - in Tab and Mobile auto. in 40px steps
     * @default 2 = 160px
     */
    spacingStep: number;
    modules: ReadonlyArray<TApiAreaOverviewModule>;
    returnUrl?: string;
    redirectUrl?: string;
}

export interface IAreaOverviewProps extends IBasePageProps<IAreaOverviewData, IAreaOverviewDict> {}

export const fetchAreaOverview = async (slug: string, context: GetServerSidePropsContext<IBaseParam>): Promise<IAreaOverviewProps | null> => {
    try {
        return await fetchUmbraco(createAreaOverviewPageUrl(slug), context);
    } catch (error) {
        logger.error({ message: FETCHING_AREA_OVERVIEW_ERROR_MESSAGE, error, slug });
        return null;
    }
};
